import { HTTP_METHODS, TAG } from '@/const';

export const CARD_CONFIGURATION = [
	{
		id: '3101',
		title: 'Activate Physical Card',
		titleCN: '实名卡激活',
		endpoint: '/app/cardInfoActive',
		method: HTTP_METHODS.POST,
		tag: TAG.CARD_CONFIGURATION,
		attributes: [
			'cardIdEnc',
			'custId',
			'cvn2',
			'endDt',
			'mobile',
			'nameEnc',
			'newPinEnc',
			'pidNoEnc',
			'pidType',
		],
		optionalAttributes: ['cvn2', 'endDt', 'mobile', 'nameEnc', 'pidNoEnc', 'pidType'],
		respAttributes: ['brandId', 'cardId4', 'extCardId', 'prdtNo'],
	},
	{
		id: '3102',
		title: 'Query Card Info (Single Card)',
		titleCN: '卡信息查询 [单卡]',
		endpoint: '/app/cardInfoQuery',
		method: HTTP_METHODS.POST,
		tag: TAG.CARD_CONFIGURATION,
		attributes: ['custId', 'extCardId'],
		respAttributes: [
			'acctId',
			'acctSta',
			'brandId',
			'cardId4',
			'cardSta',
			'currAvailAt',
			'currBalAt',
			'defFlag',
			'endDt',
			'extCardId',
			'lastTxnDt',
			'lockAt',
			'prdtNo',
			'referralCode',
			'startDt',
			'upiState',
		],
	},
	{
		id: '3103',
		title: 'Query Card Limitation',
		titleCN: '查询卡限额',
		endpoint: '/app/queryCardLimit',
		method: HTTP_METHODS.POST,
		tag: TAG.CARD_CONFIGURATION,
		attributes: ['custId', 'extCardId'],
		respAttributes: [
			'cardBrhId',
			'cardBrhNm',
			'cardId4',
			'consumeDayMax',
			'consumeDayMaxNum',
			'consumeSingleMax',
			'sysConsumeDayMax',
			'sysConsumeDayMaxNum',
			'sysConsumeSingleMax',
			'sysWithdrawDayMax',
			'sysWithdrawDayMaxNum',
			'sysWithdrawSingleMax',
			'withdrawDayMax',
			'withdrawDayMaxNum',
			'withdrawSingleMax',
		],
	},
	{
		id: '3104',
		title: 'Set Card Limitation',
		titleCN: '设置卡限额',
		endpoint: '/app/setCardLimit',
		method: HTTP_METHODS.POST,
		tag: TAG.CARD_CONFIGURATION,
		attributes: [
			'consumeDayMax',
			'consumeDayMaxNum',
			'consumeSingleMax',
			'custId',
			'extCardId',
			'withdrawDayMax',
			'withdrawDayMaxNum',
			'withdrawSingleMax',
		],
		respAttributes: ['cardId4'],
	},
	{
		id: '3105',
		title: 'Freeze Card',
		titleCN: '卡冻结',
		endpoint: '/app/applyFreezeCard',
		method: HTTP_METHODS.POST,
		tag: TAG.CARD_CONFIGURATION,
		attributes: ['custId', 'extCardId'],
		respAttributes: ['cardId4', 'endDt'],
	},
	{
		id: '3106',
		title: 'Unfreeze Card',
		titleCN: '卡解冻',
		endpoint: '/app/applyUnfreezeCard',
		method: HTTP_METHODS.POST,
		tag: TAG.CARD_CONFIGURATION,
		attributes: ['extCardId', 'custId'],
		respAttributes: ['cardId4', 'endDt'],
	},
	{
		id: '3107',
		title: 'Close Card',
		titleCN: '卡注销',
		endpoint: '/app/cardClose',
		method: HTTP_METHODS.POST,
		tag: TAG.CARD_CONFIGURATION,
		attributes: ['custId', 'extCardId', 'pidNoEnc', 'pidType'],
		optionalAttributes: ['pidNoEnc', 'pidType'],
		respAttributes: ['cardId4', 'endDt'],
	},
	{
		id: '3108',
		title: 'Change Card PIN',
		titleCN: '卡密码修改',
		endpoint: '/app/cardChgPwd',
		method: HTTP_METHODS.POST,
		tag: TAG.CARD_CONFIGURATION,
		attributes: ['custId', 'extCardId', 'newPinEnc', 'pinEnc'],
		respAttributes: ['endDt'],
	},
	{
		id: '3109S',
		title: 'Reset Card PIN',
		titleCN: '忘记卡密码',
		endpoint: '/app/resetCardPwd',
		method: HTTP_METHODS.POST,
		tag: TAG.CARD_CONFIGURATION,
		attributes: ['custId', 'extCardId', 'pinEnc'],
		respAttributes: ['cardId4', 'endDt'],
	},
];
