export const SMILESHOP = {
	include: [
		// virtual card
		'1101',
		'1102',
		'1103',
		'1104',
		// '/app/virtualCardOpen',
		// '/app/virtualCardOpenForOld',
		// '/app/virtualCardSensQuery',
		// '/app/virtualAppliDetail',

		// physical card
		'1201',
		'1202',
		'1203',
		'1204S',
		// '/app/physicalCardOpen',
		// '/app/physicalCardAdd',
		// '/app/physicalCardAppliDetail',
		// '/app/physicalCardSensQuery',

		// verification
		'2101',
		// '/app/cardUserVerify',

		// query cardholder info
		'2211',
		'2221',
		'2232',
		'2233',
		'2234',
		'2241',
		'2242',
		'2243',
		// '/app/cardBatchQueryInfo',
		// '/app/queryOrderDetail',
		// '/app/queryOrder',
		// '/app/queryAccount',
		// '/app/custInfoQueryByCell',
		// '/app/custInfoQueryByPid',
		// '/app/custInfoQuery',

		// modify cardholder info

		// card configuration
		'3101',
		'3103',
		'3104',
		'3105',
		'3106',
		'3107',
		'3108',
		// '/app/cardInfoActive',
		// '/app/queryCardLimit',
		// '/app/setCardLimit',
		// '/app/cardChgPwd',
		// '/app/resetCardPwd',
		// '/app/applyFreezeCard',
		// '/app/applyUnfreezeCard',
		// '/app/cardClose',

		// internal transfer
		'4101',
		'4102',
		// '/app/cardTransfer',
		// '/app/topUp',

		// bakong transaction
		'4211',
		'4212',
		'4221',
		'4222',
		'4223',
		'4231',
		'4232',
		'4233',
		// '/app/bakongQueryBakongAccount',
		// '/app/bakongTopUpWallet',
		// '/app/bakongQueryBankList',
		// '/app/bakongQueryOtherBankAccount',
		// '/app/bakongMemberTransfer',
		// '/app/bakongDecodeKHQR',
		// '/app/bakongVerifyKHQR',
		// '/app/bakongGenIndividualKHQR',

		// umps
		'4312',
		'4313',
		// '/app/qrCodeInfoQuery',
		// '/app/scanReceivingQR',

		// umts
		// others

		// notification
		'NTF1',
		'NTF2',
		'NTF3',
		'NTF4',
		'NTF5',
		'NTF6',
		'NTF7',
		'NTF8',
		'NTF9',
		'NTF10',
		'NTF11',
		'NTF12',
		'NTF13',
		'NTF14',
		// 'yourNotificationUrl',
	],
};
