import { HTTP_METHODS, TAG } from '@/const';

export const QUERY_CARDHOLDER_INFO = [
	{
		id: '2211',
		title: 'Query Batch Card Information',
		titleCN: '客户卡查询',
		endpoint: '/app/cardBatchQueryInfo',
		method: HTTP_METHODS.POST,
		tag: TAG.QUERY_CARDHOLDER_INFO,
		attributes: ['custId', 'extCardId'],
		respAttributes: ['cardBatchQueryInfoModelList', 'custId'],
	},
	{
		id: '2221',
		title: 'Query Batch Account Information',
		titleCN: '客户账户查询',
		endpoint: '/app/queryAccount',
		method: HTTP_METHODS.POST,
		tag: TAG.QUERY_CARDHOLDER_INFO,
		attributes: ['custId'],
		respAttributes: ['accountQueryInfoModelList'],
	},
	{
		id: '2231',
		title: 'Query Cardholder Info',
		titleCN: '查询持卡人信息',
		endpoint: '/app/queryCardholderInfo',
		method: HTTP_METHODS.POST,
		tag: TAG.QUERY_CARDHOLDER_INFO,
		attributes: ['custId'],
		respAttributes: [
			'addr',
			'dob',
			'email',
			'khNameEnc',
			'memberEnrolledDate',
			'memberId',
			'mobile',
			'nameEnc',
			'nationalityCode',
			'organizationName',
			'pidNoEnc',
			'pidType',
			'sex',
		],
	},
	{
		id: '2232',
		title: 'Check Customer Name by KeyId',
		titleCN: '持卡人姓名查询',
		endpoint: '/app/custInfoQuery',
		method: HTTP_METHODS.POST,
		tag: TAG.QUERY_CARDHOLDER_INFO,
		attributes: ['keyId'],
		respAttributes: ['nameEnc', 'keyStatus', 'prdtNo', 'referralCode'],
	},
	{
		id: '2233',
		title: 'Check Customer Information by Document Number',
		titleCN: '客户信息查询 [根据证件号]',
		endpoint: '/app/custInfoQueryByPid',
		method: HTTP_METHODS.POST,
		tag: TAG.QUERY_CARDHOLDER_INFO,
		attributes: ['pidNoEnc', 'pidType'],
		respAttributes: [
			'addr',
			'dob',
			'email',
			'khNameEnc',
			'memberEnrolledDate',
			'memberId',
			'mobile',
			'nameEnc',
			'nationalityCode',
			'organizationName',
			'pidNoEnc',
			'pidType',
			'sex',
		],
	},
	{
		id: '2234',
		title: 'Check Customer Information by Phone Number',
		titleCN: '客户信息查询 [根据手机号]',
		endpoint: '/app/custInfoQueryByCell',
		method: HTTP_METHODS.POST,
		tag: TAG.QUERY_CARDHOLDER_INFO,
		attributes: ['mobile'],
		respAttributes: [
			'addr',
			'dob',
			'email',
			'khNameEnc',
			'memberEnrolledDate',
			'memberId',
			'mobile',
			'nameEnc',
			'nationalityCode',
			'organizationName',
			'pidNoEnc',
			'pidType',
			'sex',
		],
	},
	{
		id: '2235',
		title: 'Query ID Information by Card Number or Account Number',
		titleCN: '通过卡号或账户号查询证件信息',
		endpoint: '/app/custPidQueryByKeyId',
		method: HTTP_METHODS.POST,
		tag: TAG.QUERY_CARDHOLDER_INFO,
		attributes: ['keyId'],
		respAttributes: ['pidNoEnc', 'pidType'],
	},
	{
		id: '2236',
		title: 'Query ID Information by Customer Mapping ID',
		titleCN: '通过客户映射ID查询客户信息',
		endpoint: '/app/queryCustomerInfoByExtCustId',
		method: HTTP_METHODS.POST,
		tag: TAG.QUERY_CARDHOLDER_INFO,
		attributes: ['extCustId', 'pidNoEnc', 'pidType'],
		respAttributes: [
			'addr',
			'custId',
			'email',
			'mobile',
			'nameEnc',
			'nationalityCode',
			'sex',
			'tel',
		],
	},
	{
		id: '2241',
		title: 'Query Batch Transaction Details',
		titleCN: '查询交易明细',
		endpoint: '/app/queryOrderDetail',
		method: HTTP_METHODS.POST,
		tag: TAG.QUERY_CARDHOLDER_INFO,
		attributes: [
			'acctId',
			'acctIdIN',
			'acctIdOUT',
			'cardId',
			'cardIdIN',
			'cardIdOUT',
			'custId',
			'endDt',
			'extCardId',
			'pageNum',
			'pageSize',
			'startDt',
			'txnCategory',
			'txnSeqId',
			'txnType',
		],
		optionalAttributes: [
			'acctId',
			'acctIdIN',
			'acctIdOUT',
			'cardId',
			'cardIdIN',
			'cardIdOUT',
			'endDt',
			'extCardId',
			'startDt',
			'txnCategory',
			'txnSeqId',
			'txnType',
		],
		respAttributes: ['cardTxnLogListList', 'totalCount'],
	},
	{
		id: '2242',
		title: 'Query Single Transaction Details',
		titleCN: '查询交易明细 [单笔]',
		endpoint: '/app/queryOrder',
		method: HTTP_METHODS.POST,
		tag: TAG.QUERY_CARDHOLDER_INFO,
		attributes: ['custId', 'id'],
		respAttributes: [
			'acctId',
			'acctIdIN',
			'acctIdOUT',
			'acctNmIN',
			'acctNmOUT',
			'availAt',
			'bakongType',
			'cardBrhNmIN',
			'cardBrhNmOUT',
			'cardIdEnc',
			'cardIdIN',
			'cardIdOUT',
			'cashflowType',
			'currBalAt',
			'custId',
			'exchangeRateFee',
			'extCardId',
			'extCardIdIN',
			'extCardIdOUT',
			'externalTxnRefId',
			'fee',
			'id',
			'instituteIdIN',
			'instituteIdOUT',
			'instituteNmIN',
			'instituteNmOUT',
			'merType',
			'merchantId',
			'merchantNm',
			'note',
			'operatorId',
			'operatorNm',
			'originalTxnAmt',
			'originalTxnCurrency',
			'qrCode',
			// 'settledAmt',
			'settledWithCustomerAmt',
			'terminalId',
			'terminalLocation',
			// 'txnAt',
			'txnCategory',
			'txnDt',
			'txnHash',
			'txnRefId',
			'txnSeqId',
			'txnStaCd',
			'txnTm',
			'txnType',
		],
	},
	{
		id: '2243',
		title: 'Query Top Up Transaction List',
		titleCN: '查询充值明细',
		endpoint: '/app/queryAllTopUpTxn',
		method: HTTP_METHODS.POST,
		tag: TAG.QUERY_CARDHOLDER_INFO,
		attributes: [
			'endDt',
			'externalTxnRefId',
			'pageNum',
			'pageSize',
			'startDt',
			'txnRefId',
			'txnSeqId',
		],
		optionalAttributes: ['endDt', 'externalTxnRefId', 'startDt', 'txnRefId', 'txnSeqId'],
		respAttributes: ['topUpTxnLogList', 'totalCount'],
	},
];
