export const EKYC = {
	include: [
		// virtual card
		'1101',
		'1102',
		'1103',
		'1104',
		// '/app/virtualCardOpen',
		// '/app/virtualCardOpenForOld',
		// '/app/virtualAppliDetail',
		// '/app/virtualCardSensQuery',

		// physical card

		// verification
		'2101',
		// '/app/cardUserVerify',

		// query cardholder info
		'2211',
		'2221',
		'2241',
		'2242',
		// '/app/queryAccount',
		// '/app/cardBatchQueryInfo',
		// '/app/queryOrderDetail',
		// '/app/queryOrder',
		// '/app/queryOrder',

		// modify cardholder info

		// card configuration
		'3103',
		'3104',
		'3105',
		'3106',
		'3107',
		// '/app/queryCardLimit',
		// '/app/setCardLimit',
		// '/app/applyFreezeCard',
		// '/app/applyUnfreezeCard',
		// '/app/cardClose',

		// internal transfer
		'4101',
		'4102',
		// '/app/topUp',
		// '/app/cardTransfer',
		// bakong transaction

		// umps
		'4313',
		// '/app/scanReceivingQR',

		// umts
		// others
		// notificaiton
	],
};
