export const BAKONG = {
	include: [
		// virtual card
		'1101',
		'1102',
		'1103',
		'1104',
		// '/app/virtualCardOpen',
		// '/app/virtualCardOpenForOld',
		// '/app/virtualCardSensQuery',
		// '/app/virtualAppliDetail',

		// physical card
		'1201',
		'1202',
		'1203',
		'1205S',
		'1206S',
		// '/app/physicalCardOpen',
		// '/app/physicalCardAdd',
		// '/app/physicalCardAppliDetail',
		// '/app/physicalCheck',
		// '/app/requestOpenCardNoAudit',

		// verification
		'2101',
		// '/app/cardUserVerify',

		// query cardholder info
		'2211',
		'2221',
		'2232',
		'2233',
		'2234',
		'2236',
		'2241',
		'2242',
		// '/app/cardBatchQueryInfo',
		// '/app/queryOrderDetail',
		// '/app/queryOrder',
		// '/app/queryAccount',
		// '/app/custInfoQueryByCell',
		// '/app/custInfoQueryByPid',
		// '/app/custInfoQuery',

		// modify cardholder info
		'2301',
		// '/app/custInfoModifyCell',

		// card configuration
		'3101',
		'3103',
		'3104',
		'3105',
		'3106',
		'3107',
		'3108',
		// '/app/cardInfoActive',
		// '/app/applyFreezeCard',
		// '/app/applyUnfreezeCard',
		// '/app/cardClose',
		// '/app/queryCardLimit',
		// '/app/setCardLimit',
		// '/app/cardChgPwd',

		// internal transfer
		'4102',
		// '/app/cardTransfer',

		// bakong transaction
		'4212',
		'4223',
		// '/app/bakongTopUpWallet',
		// '/app/bakongMemberTransfer',

		// umps
		'4312',
		'4313',
		'4321',

		// umts

		// ohters
		'N3',
		'N5',

		// notificaiton
		'NTF1',
		'NTF2',
		'NTF3',
		'NTF4',
		'NTF5',
		'NTF6',
		'NTF7',
		'NTF8',
		'NTF9',
		'NTF10',
		'NTF11',
		'NTF12',
		'NTF13',
		'NTF14',
		'NTF15',
		// 'yourNotificationUrl',
	],
};
