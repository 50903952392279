import { HTTP_METHODS, TAG } from '@/const';

export const VERIFICATION = [
	{
		id: '2101',
		title: 'Cardholder Verification',
		titleCN: '验证用户信息',
		endpoint: '/app/cardUserVerify',
		method: HTTP_METHODS.POST,
		tag: TAG.VERIFICATION,
		attributes: ['keyId', 'mobile', 'nameEnc', 'pidNoEnc', 'pidType'],
		optionalAttributes: ['nameEnc', 'pidNoEnc', 'pidType'],
		respAttributes: [
			'addr',
			'custId',
			'email',
			'mobile',
			'nameEnc',
			'nationalityCode',
			'sex',
			'tel',
		],
	},
	{
		id: '2102',
		title: "Query Card's Information and Verification",
		titleCN: '卡信息校验',
		endpoint: '/app/cardQueryBalanceAndVerify',
		method: HTTP_METHODS.POST,
		tag: TAG.VERIFICATION,
		attributes: [
			'cardIdEnc',
			'cvn2',
			'endDt',
			'mobile',
			'nameEnc',
			'pidNoEnc',
			'pidType',
			'pinEnc',
		],
		optionalAttributes: ['cvn2', 'endDt', 'mobile', 'nameEnc', 'pidNoEnc', 'pidType', 'pinEnc'],
		respAttributes: [
			'cardBrhId',
			'cardId4',
			'cardQueryInfoModelList',
			'cardSta',
			'endDt',
			'pidNoEnc',
			'pidType',
			'startDt',
		],
	},
	{
		id: '2103B',
		title: 'Query Mapping ID',
		titleCN: '查询映射客户ID',
		endpoint: '/app/queryMappingId',
		method: HTTP_METHODS.POST,
		tag: TAG.VERIFICATION,
		attributes: ['mobile', 'pidNoEnc', 'pidType'],
		optionalAttributes: ['mobile', 'pidNoEnc', 'pidType'],
		respAttributes: ['mappingId'],
	},
	{
		id: '2121',
		title: 'Send Verification Code',
		titleCN: '发送验证码',
		endpoint: '/app/sendVerCode',
		method: HTTP_METHODS.POST,
		tag: TAG.VERIFICATION,
		attributes: ['accountId', 'email'],
		optionalAttributes: ['accountId', 'email'],
		respAttributes: [],
	},
	{
		id: '2122',
		title: 'Account Authentication',
		titleCN: '账户验证',
		endpoint: '/app/accountAuthentication',
		method: HTTP_METHODS.POST,
		tag: TAG.VERIFICATION,
		attributes: ['email', 'verCode'],
		respAttributes: ['custId'],
	},
];
