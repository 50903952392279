import { ENUMS } from '../enums';
import { VALUES } from '../values';

export const TRANSACTION = [
	{
		key: 'intTxnRefId',
		description: 'Transaction Reference Number',
		example: '',
		type: 'String',
	},
	{
		key: 'intTxnSeqId',
		description: 'Transaction Sequence Number',
		example: '',
		type: 'String',
	},
	{
		key: 'txnRefId',
		description: 'Transaction Reference Number',
		example: '',
		type: 'String',
	},
	{
		key: 'txnSeqId',
		description: 'Transaction Sequence Number',
		example: '',
		type: 'String',
	},
	{
		key: 'txnDt',
		description: 'Transaction Date',
		example: '',
		type: 'String',
	},
	{
		key: 'txnTm',
		description: 'Transaction Time',
		example: '',
		type: 'String',
	},
	{
		key: 'lastTxnDt',
		description: 'Last Transaction Date',
		example: '',
		type: 'String',
	},
	{
		key: 'txnCode',
		description: 'Transaction code',
		example: '',
		type: 'String',
	},
	{
		key: 'txnStaCd',
		description: 'Transaction Status Code',
		example: '2',
		enum: ENUMS.transactionStatusCodeEnum,
		type: 'String',
	},
	{
		key: 'txnID',
		description: 'Transaction ID',
		example: '',
		type: 'String',
	},
	{
		key: 'txnId',
		description: 'Transaction ID',
		example: '',
		type: 'String',
	},
	{
		key: 'txnAt',
		description: 'Transfer Amount (in USD cents)',
		example: '',
		type: 'String',
	},
	{
		key: 'transactionAmount',
		description: 'Transaction Amount',
		example: '',
		type: 'String',
	},
	{
		key: 'txnAmt',
		description: 'Transaction Amount',
		example: '',
		type: 'String',
	},
	{
		key: 'txnCurrency',
		description: 'Transaction Currrency',
		example: '840 (USD Code)',
		type: 'String',
	},
	{
		key: 'transactionCurrency',
		description: 'Transaction Currency',
		example: '',
		type: 'String',
	},
	{
		key: 'txnType',
		description: 'Transaction Type',
		example: 'B0030',
		enum: ENUMS.txnTypeEnum,
		type: 'String',
	},
	{
		key: 'txnCategory',
		description: 'Transaction Category',
		example: '5',
		enum: ENUMS.txnCategoryEnum,
		type: 'String',
	},
	{
		key: 'transactionCategory',
		description: 'transaction Category',
		example: '',
		value: VALUES.transactionCategoryValue,
		type: 'String',
	},
	{
		key: 'originalTransactionAmount',
		description: 'Original Transaction Amount',
		example: '',
		type: 'String',
	},
	{
		key: 'originalTxnAmt',
		description: 'Original Transaction Amount',
		example: '',
		type: 'String',
	},
	{
		key: 'originalTxnAt',
		description: 'Original Transaction Amount',
		example: '',
		type: 'String',
	},
	{
		key: 'originalTxnCurrency',
		description: 'Original Transaction Currency',
		example: '',
		type: 'String',
	},
	{
		key: 'originalTransactionCurrency',
		description: 'Original Transaction Currency',
		example: '',
		type: 'String',
	},
	{
		key: 'transactionType',
		description: 'Transaction Type',
		example: '',
		value: VALUES.transactionTypeValue,
		type: 'String',
	},
	{
		key: 'transactionId',
		description: 'Transaction ID',
		example: '',
		type: 'String',
	},
	{
		key: 'usdAmt',
		description: 'USD Amount (in USD cents)',
		example: '',
		type: 'String',
	},
	{
		key: 'usdCurrencyCode',
		description: 'USD Currency Code',
		example: '',
		type: 'String',
	},
	{
		key: 'currencyCode',
		description: 'Currency Code',
		example: '',
		type: 'String',
	},
	{
		key: 'externalTxnRefId',
		description: 'External Transaction Reference Number',
		example: '',
		type: 'String',
	},
];
