import { ENUMS } from '../enums';

export const CARD = [
	{
		key: 'extCardId',
		description: 'Card Mapping ID (Unique to Every Card)',
		example: 'bdfb8456-aada-4625-a8a8-600a0d3bdb3f',
		type: 'String',
	},
	{
		key: 'extCardIds',
		description: 'Card Mapping ID (Unique to Every Card)',
		example: '',
		type: 'Array',
		attributes: [],
	},
	{
		key: 'extCardIdIN',
		description: "Receiver's Card Mapping ID (Unique to Every Card)",
		example: 'bdfb8456-aada-4625-a8a8-600a0d3bdb3f',
		type: 'String',
	},
	{
		key: 'extCardIdOUT',
		description: "Sender's Card Mapping ID (Unique to Every Card)",
		example: 'bdfb8456-aada-4625-a8a8-600a0d3bdb3f',
		type: 'String',
	},
	{
		key: 'brandId',
		description: 'Card Brand (4 Digits)',
		example: '0002',
		enum: ENUMS.brandIdEnum,
		type: 'String',
	},
	{
		key: 'cardIdEnc',
		description: 'Card Number',
		encrypted: true,
		example:
			'OGW2IOdpG/Gx2+Z7WVlWRYOENkpwskrZVJyAV1UxIGLS1ENm68+mCjAvQC5sAlB5aVmpMgCmaSIJM2TJVOM1MxGwX5+shGVUIS9+5JGuu4rFQ4d8zCCs2NxZHd7zZ6igKIhpCLS0s6vSijgwNcbZ0gtsbSfCvdzSGDR7TCcFgHFhZTHdOpQGuULRK+oY4MppJJWp9TI3hRbhkfJSnKcdi+cO4N970Sy+6N6CcBLYhrW4Nd8GLoq2Zgvcn/iZjXEMghHBgz91JvV/G3mmMjvMXZTSryCrRvENpX7FUBkk2lrk9H7vp8WjOuTlsxbvuu7U78iS6oP7Ahuar2I+I1b3jA==',
		type: 'String',
	},
	{
		key: 'cardIdEncIN',
		description: "Receiver's Card Number",
		encrypted: true,
		example:
			'OGW2IOdpG/Gx2+Z7WVlWRYOENkpwskrZVJyAV1UxIGLS1ENm68+mCjAvQC5sAlB5aVmpMgCmaSIJM2TJVOM1MxGwX5+shGVUIS9+5JGuu4rFQ4d8zCCs2NxZHd7zZ6igKIhpCLS0s6vSijgwNcbZ0gtsbSfCvdzSGDR7TCcFgHFhZTHdOpQGuULRK+oY4MppJJWp9TI3hRbhkfJSnKcdi+cO4N970Sy+6N6CcBLYhrW4Nd8GLoq2Zgvcn/iZjXEMghHBgz91JvV/G3mmMjvMXZTSryCrRvENpX7FUBkk2lrk9H7vp8WjOuTlsxbvuu7U78iS6oP7Ahuar2I+I1b3jA==',
		type: 'String',
	},
	{
		key: 'cardId',
		description: 'Card number',
		example: '2345234523452345',
		type: 'String',
	},
	{
		key: 'cardIdIN',
		description: "Receiver's card number",
		example: '2345234523452345',
		type: 'String',
	},
	{
		key: 'cardIdOUT',
		description: "Sender's card number",
		example: '2345234523452345',
		type: 'String',
	},
	{
		key: 'cardId4',
		description: "Last 4 Digits of Card's Number",
		example: '6789',
		type: 'String',
	},
	{
		key: 'cardIdIN4',
		description: "Last 4 Digits of Reciever's Card",
		example: '6789',
		type: 'String',
	},
	{
		key: 'cardIdOUT4',
		description: "Last 4 Digits of Sender's Card",
		example: '6789',
		type: 'String',
	},
	{
		key: 'cardId4IN',
		description: "Receiver's Last 4 Digits of Card's Number",
		example: '6789',
		type: 'String',
	},
	{
		key: 'cardId4OUT',
		description: "Sender's Last 4 Digits of Card's Number",
		example: '6789',
		type: 'String',
	},
	{
		key: 'cardBrhId',
		description: "Card's Institution's ID",
		example: '',
		type: 'String',
	},
	{
		key: 'cardBrhIdIN',
		description: "Receiver's Card's Institution's ID",
		example: '',
		type: 'String',
	},
	{
		key: 'cardBrhIdOUT',
		description: "Sender's Card's Institution's ID",
		example: '',
		type: 'String',
	},
	{
		key: 'cardBrhNm',
		description: " Card's Institution's Name",
		example: '',
		type: 'String',
	},
	{
		key: 'cardBrhNmIN',
		description: "Receiver's Card's Institution's Name",
		example: '',
		type: 'String',
	},
	{
		key: 'cardBrhNmOUT',
		description: "Sender's Card's Institution's Name",
		example: '',
		type: 'String',
	},
	{
		key: 'type',
		description: 'Card Type',
		example: '1',
		enum: ENUMS.cardTypeEnum,
		type: 'String',
	},
	{
		key: 'cvn2',
		description: "Card's CVV (3 Digits)",
		example: '789',
		type: 'String',
	},
	{
		key: 'cvn2Enc',
		description: "Card's CVV (3 Digits)",
		encrypted: true,
		example: '789',
		type: 'String',
	},
	{
		key: 'virtuAppId',
		description: 'Virtual Card Application ID',
		example: '',
		type: 'String',
	},
	{
		key: 'vouId',
		description: 'Virtual Card Number (16 Digits)',
		example: '2345234523452345',
		type: 'String',
	},
	{
		key: 'physicalAppId',
		description: 'Physical Card Application ID (Longest 32)',
		example: '',
		type: 'String',
	},
	{
		key: 'appId',
		description: 'Application ID (Physical/Virtual)',
		example: '',
		type: 'String',
	},
	{
		key: 'applicationId',
		description: 'Physical Card Application ID (Longest 32)',
		example: '',
		type: 'String',
	},
	{
		key: 'applicationProgress',
		description: 'Physical Card Printing Progress',
		example: '01',
		enum: ENUMS.physicalAppProgessEnum,
		type: 'String',
	},
	{
		key: 'cardBatchQueryInfoModelList',
		description: 'List of Cards',
		example: '',
		type: 'Array',
		subType: 'Object',
		attributes: [
			'acctId',
			'acctSta',
			'brandId',
			'cardId4',
			'cardSta',
			'currAvailAt',
			'currBalAt',
			'defFlag',
			'endDt',
			'extCardId',
			'lastTxnDt',
			'lockAt',
			'nameEnc',
			'prdtNo',
			'referralCode',
			'startDt',
			'upiState',
		],
	},
	{
		key: 'cardTxnLogListList',
		description: 'List of Transactions',
		example: '',
		type: 'Array',
		subType: 'Object',
		attributes: [
			'acctId',
			'acctIdIN',
			'acctIdOUT',
			'acctNmIN',
			'acctNmOUT',
			'availAt',
			'bakongType',
			'cardBrhNmIN',
			'cardBrhNmOUT',
			'cardIdEnc',
			'cardIdIN',
			'cardIdOUT',
			'cashflowType',
			'currBalAt',
			'custId',
			'exchangeRateFee',
			'extCardId',
			'extCardIdIN',
			'extCardIdOUT',
			'externalTxnRefId',
			'fee',
			'feeCurrency',
			'id',
			'instituteIdIN',
			'instituteIdOUT',
			'instituteNmIN',
			'instituteNmOUT',
			'merType',
			'merchantId',
			'merchantNm',
			'note',
			'operatorId',
			'operatorNm',
			'originalTxnAmt',
			'originalTxnCurrency',
			'qrCode',
			// 'settledAmt',
			'settledWithCustomerAmt',
			'settledWithCustomerCurrency',
			'terminalId',
			'terminalLocation',
			'txnAt',
			'txnCategory',
			'txnDt',
			'txnHash',
			'txnRefId',
			'txnSeqId',
			'txnStaCd',
			'txnTm',
			'txnType',
		],
	},
	{
		key: 'cardQueryInfoModelList',
		description: 'Single Card Information',
		example: '',
		type: 'Array',
		subType: 'Object',
		attributes: [
			'acctId',
			'acctSta',
			'currAvailAt',
			'currBalAt',
			'defFlag',
			'lastTxnDt',
			'lockAt',
			'prdtNo',
		],
	},
	{
		key: 'currCode',
		description: 'Currency Code (Capital Letters, Length 3 Digits)',
		example: 'CNY',
		type: 'String',
	},
	{
		key: 'currName',
		description: 'Currency Name',
		example: '',
		type: 'String',
	},
	{
		key: 'currAvailAtOUT',
		description: "Sender's Available Balance After Transfer",
		example: '10000',
		type: 'String',
	},
	{
		key: 'currBalAtOUT',
		description: "Sender's Balance After Transfer",
		example: '10000',
		type: 'String',
	},
	{
		key: 'currAvailAt',
		description: 'Current Available balance',
		example: '10000',
		type: 'String',
	},
	{
		key: 'currBalAt',
		description: 'Current Balance',
		example: '10000',
		type: 'String',
	},
	{
		key: 'availAt',
		description: 'Available Balance (in USD Cents)',
		example: '10000',
		type: 'String',
	},
	{
		key: 'lockAt',
		description: 'Frozen Balance',
		example: '100',
		type: 'String',
	},
	{
		key: 'lockAtOUT',
		description: "Sender's Frozen Amount",
		example: '100',
		type: 'String',
	},
	{
		key: 'cashflowType',
		description: 'Type of Cashflow',
		example: '1',
		enum: ENUMS.cashflowTypeEnum,
		type: 'String',
	},
	{
		key: 'topUpTxnLogList',
		description: 'List of Top Up Transactions',
		example: '',
		type: 'Array',
		subType: 'Object',
		attributes: [
			'acctIdIN',
			'acctNmIN',
			'cardIdEncIN',
			'cashflowType',
			'externalTxnRefId',
			'id',
			'instituteIdIN',
			'instituteIdOUT',
			'instituteNmIN',
			'instituteNmOUT',
			'note',
			'operatorId',
			'operatorNm',
			'originalTxnAmt',
			'originalTxnCurrency',
			'settledWithCustomerAmt',
			'txnDt',
			'txnRefId',
			'txnSeqId',
			'txnStaCd',
			'txnTm',
		],
	},
	{
		key: 'anonCardList',
		description: 'List of Anonymous Cards',
		example: '',
		type: 'Array',
		subType: 'Object',
		attributes: [
			'activatedDate',
			'cardIdEnc',
			'cardSta',
			'currAvailAt',
			'currBalAt',
			'enabledDate',
			'endDt',
			'extCardId',
			'fundsExpiryDate',
			'prdtNo',
			'serialNum',
			'startDt',
		],
	},
	{
		key: 'activatedDate',
		description: 'Activated Date',
		example: '',
		type: 'String',
	},
	{
		key: 'enabledDate',
		description: 'Enabled Date',
		example: '',
		type: 'String',
	},
	{
		key: 'serialNum',
		description: 'Serial Number',
		example: '',
		type: 'String',
	},
	{
		key: 'fundsExpiryDate',
		description: 'Funds Expiry Date',
		example: '',
		type: 'String',
	},
];
