import { HTTP_METHODS, TAG } from '@/const';

export const VIRTUAL_CARD = [
	{
		id: '1101',
		title: 'Request Virtual Card (New User)',
		titleCN: '虚拟卡开卡申请 [新客户]',
		endpoint: '/app/virtualCardOpen',
		method: HTTP_METHODS.POST,
		tag: TAG.VIRTUAL_CARD,
		attributes: [
			'addr',
			'appliOpr',
			'dob',
			'brandId',
			'email',
			'headPhotos',
			'idPhotos',
			'mobile',
			'nameEnc',
			'nationalityCode',
			'pidNoEnc',
			'pidType',
			'referralCode',
			'sex',
			'tel',
			'zip',
		],
		optionalAttributes: ['dob', 'email', 'referralCode', 'sex', 'tel', 'zip'],
		respAttributes: ['virtuAppId'],
	},
	{
		id: '1102',
		title: 'Request Additional Virtual Card (Old User)',
		titleCN: '虚拟卡开卡申请 [老客户]',
		endpoint: '/app/virtualCardOpenForOld',
		method: HTTP_METHODS.POST,
		tag: TAG.VIRTUAL_CARD,
		attributes: [
			'appliOpr',
			'brandId',
			'custId',
			'mobile',
			'nameEnc',
			'pidNoEnc',
			'pidType',
			'referralCode',
		],
		optionalAttributes: ['referralCode'],
		respAttributes: ['cardId4', 'extCardId'],
	},
	{
		id: '1103',
		title: 'Query Requesting Virtual Card Progress',
		titleCN: '虚拟卡申请单详情',
		endpoint: '/app/virtualAppliDetail',
		method: HTTP_METHODS.POST,
		tag: TAG.VIRTUAL_CARD,
		attributes: ['virtuAppId'],
		respAttributes: [
			'addr',
			'custId',
			'email',
			'extCardId',
			'headPicUrlFlag',
			'mobile',
			'nameEnc',
			'nationalityCode',
			'note',
			'pidNoEnc',
			'pidNoUrlFlag',
			'pidType',
			'sex',
			'sta',
			'tel',
			'vouId',
			'zip',
		],
	},
	{
		id: '1104',
		title: 'Query Virtual Card Sensitive Data',
		titleCN: '查看虚拟卡敏感信息',
		endpoint: '/app/virtualCardSensQuery',
		method: HTTP_METHODS.POST,
		tag: TAG.VIRTUAL_CARD,
		attributes: ['custId', 'extCardId'],
		respAttributes: ['brandId', 'cardIdEnc', 'cardSta', 'cvn2', 'endDt', 'startDt'],
	},
];
